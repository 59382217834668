import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  Upload,
  InputNumber,
  message,
  Checkbox,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useProduct } from "./store";
import Input from "antd/lib/input/Input";
import { getProductGroup, createProduct, editProduct } from "../../api";
import TextArea from "antd/lib/input/TextArea";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const { Title } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

export const PureCreate = ({ vendorID }) => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    name,
    endTime,
    productGroupID,
    price,
    description,
    setStatus,
    status,
    setDescription,
    setEndTime,
    setId,
    setName,
    setPrice,
    setProductGroupID,
    setPreparationTime,
    preparationTime,
    priority,
    setPriority,
    productGroupName,
  } = useProduct();
  const [form] = Form.useForm();
  const [groupSearch, setGroupSearch] = useState("");

  const {
    isLoading: groupLoading,
    // error: groupsError,
    data: groups,
  } = useQuery(["productGroup", groupSearch, 1, 10], getProductGroup, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!isCreateModal,
  });

  // const {
  //   // isLoading: resturantLoading,
  //   // error: resturantsError,
  //   data: resturants,
  // } = useQuery("resturants", getResturants, {
  //   retry: 2,
  // });

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const { mutate, isLoading } = useMutation(createProduct, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("products");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editProduct,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("products");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.vendorID = Number(vendorID);
    values.endTime = dayjs(values.endTime).toISOString();
    values.description = values.description || "";
    values.sync = values.sync || false;
    // values.priority = values.priority;
    if (id) {
      values.id = id;
      values.status = status;
      editMutate(values);
    } else {
      values.imgFile = values.imgFile.file;
      mutate(values);
    }
  };

  let resetState = () => {
    setId(null);
    setIsCreateModal(false);
    setDescription(null);
    setEndTime(null);
    setId(null);
    setName(null);
    setPrice(null);
    setStatus(false);
    setPreparationTime(null);
    form.resetFields();
    setPriority(null);
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        endTime: endTime ? dayjs(endTime) : null,
        productGroupID: productGroupID,
        price: price || 0,
        description: description || "",
        preparationTime,
        priority,
      });
    } else {
      resetState();
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Item</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setProductGroupID(null);
      }}
      footer={false}
      destroyOnClose={true}
      width={550}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        // initialValues={{
        //   remember: true,
        //   name,
        //   endTime:endTime ? moment(endTime) : null,
        //   productGroupID,
        //   price: price || 0,
        //   description,
        // }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}

        //autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <Form.Item
              label="Item Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Cheese Burger"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Price ( IQD )"
              name="price"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                placeholder="Price"
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: false }]}
            >
              <TextArea
                rows={3}
                style={{ width: "100%" }}
                placeholder={"Write description of this Item"}
              />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              label="Group"
              name="productGroupID"
              rules={[{ required: true }]}
            >
              <Select
                //allowClear
                //value={groupID}
                //notFoundContent={fetching ? <Spin size="small" /> : null}
                showSearch
                filterOption={false}
                onSearch={setGroupSearch}
                placeholder="Choose a group"
                //onChange={(val) => setInfo(val)}
                loading={groupLoading}
              >
                {groups && groups.success
                  ? groups.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          {/*         
          <Col span={6}>
            <Form.Item
              label="End Time"
              name="endTime"
              rules={[{ required: true }]}
            >
              <TimePicker
                style={{ width: "100%" }}
                //onChange={(date, dateString)=>setEndTime(dateString) }
                //defaultValue={Date.now()}
                format={format}
              />
            </Form.Item>
          </Col> */}

          <Col span={7}>
            <Form.Item
              label={
                <div>
                  Preparation<small> ( Minute )</small>
                </div>
              }
              name="preparationTime"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          {!id ? (
            <Col span={24}>
              <Form.Item
                //label="Iamge"
                name="imgFile"
                rules={[{ required: true }]}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag Image to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    This Image will view in Product details
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={24}>
            <Form.Item
              //label="is sync ?"
              name="sync"
              valuePropName="checked"
            >
              <Checkbox> is sync ?</Checkbox>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
