import create from "zustand";

export const useProduct = create((set) => ({
  id: null,
  name: null,
  productGroupID: null,
  price: null,
  description: null,
  historyData: null,
  isCreateModal: false,
  isHistoryModal: false,
  preparationTime: null,
  status: false,
  queryKey: "",
  page: 1,
  priority: null,
  schedule: null,
  disableRule: null,
  startTime: null,
  endTime: null,
  isDisableModal: false,
  isScheduleModal: false,
  productGroupIDFilter: null,
  isCreateSortModal: false,
  productGroupName: null,
  setProductGroupName: (productGroupName) => set({ productGroupName }),

  setIsCreateSortModal: (isCreateSortModal) => set({ isCreateSortModal }),
  setIsDisableModal: (isDisableModal) => set({ isDisableModal }),
  setIsScheduleModal: (isScheduleModal) => set({ isScheduleModal }),

  setQueryKey: (queryKey) => set({ queryKey }),
  setPreparationTime: (preparationTime) => set({ preparationTime }),
  setStatus: (status) => set({ status }),
  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setProductGroupID: (productGroupID) => set({ productGroupID }),
  setProductGroupIDFilter: (productGroupIDFilter) =>
    set({ productGroupIDFilter }),
  setPrice: (price) => set({ price }),
  setDescription: (description) => set({ description }),
  setHistoryData: (historyData) => set({ historyData }),
  setEndTime: (endTime) => set({ endTime }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setIsHistoryModal: (isHistoryModal) => set({ isHistoryModal }),
  setPage: (page) => set({ page }),
  setPriority: (priority) => set({ priority }),
  setSchedule: (schedule) => set({ schedule }),
  setDisableRule: (disableRule) => set({ disableRule }),
  setStartTime: (startTime) => set({ startTime }),
  bulkProductSchedule: null,
  setBulkProductSchedule: (bulkProductSchedule) => set({ bulkProductSchedule }),
}));
