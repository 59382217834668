import React from "react";
import { Button } from "antd";
import { downloadFile } from "../../api";

const ExportCSVButton = (vendorId) => {
  const handleButtonClick = () => {
    console.log("handleButtonClick", vendorId);

    const data = {
      vendorID: vendorId.vendorId,
    };
    window.location.href = downloadFile("v1/product/export/excel", data);
  };

  return (
    <div>
      <Button onClick={handleButtonClick} type="primary">
        Export CSV
      </Button>
    </div>
  );
};

export default ExportCSVButton;
