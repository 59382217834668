import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Spin,
  Input,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useOrderView } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { changeOrderStatus, getDeclineReason } from "../../api";
import { useState, useEffect } from "react";
import authFunc from "../../helper/auth";

const { Title } = Typography;
const { Option } = Select;

export const CreateCancelReason = (paymentType) => {
  const { id } = useParams();

  const [reasonsSearch, setReasonsSearch] = useState(null);

  const {
    isCreateCancelModal,
    setIsCreateCancelModal,
    setIsSelectReason,
    selectedReason,
    setSelectedReason,
  } = useOrderView();

  const { data: reasonData, loading: reasonsLoading } = useQuery(
    ["declineReasons", reasonsSearch, 1, 500, "BACKOFFICE_USER"],
    getDeclineReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateCancelModal,
    }
  );

  const { mutate: changeStatusMutate, isLoading: changeStatusLoading } =
    useMutation(changeOrderStatus, {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsSelectReason(false);
          setSelectedReason(null);
          queryClient.invalidateQueries("orderInfo");
        } else if (data.err === "invalid OTP") {
          message.error("Invalid OTP, please try again.");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const onFinish = (val) => {
    let dataForm = {
      id: Number(id),
      status: selectedReason !== null ? selectedReason : "BACKOFFICE_REJECTED",
      declineReasonID: val.declineReasonID,
      otp: val.otp,
    };
    setIsSelectReason(true);
    changeStatusMutate(dataForm);
    setIsCreateCancelModal(false);
  };

  return (
    <Modal
      title={<Title level={5}> Select Cancel Reason </Title>}
      className="app-modal"
      centered
      visible={isCreateCancelModal}
      onCancel={() => {
        setIsCreateCancelModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      {(authFunc("ORDER_ONLINE_UPDATE") &&
        paymentType.paymentType === "ONLINE") ||
      paymentType.paymentType !== "ONLINE" ? (
        <Form
          requiredMark={"optional"}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Form.Item
                label="Cancel Reasons"
                name="declineReasonID"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  filterOption={true}
                  optionFilterProp="children"
                  onSearch={(val) => setReasonsSearch(val)}
                  style={{ width: "100%" }}
                  placeholder="Select Cancel Reasons"
                >
                  {!reasonsLoading ? (
                    reasonData?.success ? (
                      (reasonData?.data?.records ?? []).map(
                        (val) =>
                          val.userType === "BACKOFFICE_USER" && (
                            <Option key={val.id} value={val.id}>
                              {val.arTitle}
                            </Option>
                          )
                      )
                    ) : null
                  ) : (
                    <Spin spinning={true} />
                  )}
                </Select>
              </Form.Item>

              {paymentType?.paymentType === "ONLINE" && (
                <Form.Item
                  label="Otp"
                  name="otp"
                  style={{
                    marginTop: "10px",
                  }}
                  rules={[
                    { required: true, message: "OTP is required" },
                    {
                      pattern: /^\d{6}$/,
                      message: "OTP must be exactly 6 digits",
                    },
                  ]}
                >
                  <Input placeholder="Enter OTP" maxLength={6} />
                </Form.Item>
              )}
            </Col>

            <Col span={24}>
              <Divider />
              <Form.Item>
                <Button
                  loading={changeStatusLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <>
          <h3>You don't have permission to edit this order</h3>
        </>
      )}
    </Modal>
  );
};
