import { Button, Modal, Typography, message, TimePicker } from "antd";
import dayjs from "dayjs";
import { useResturant } from "./store";
import { CustomeSchedule } from "../../comonents/global";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { SaveOutlined } from "@ant-design/icons";
import { updateData } from "../../api";

const { Title } = Typography;

export const WorkingSchedule = () => {
  const { RangePicker } = TimePicker;

  const {
    isSchedual,
    setIsSchedule,
    workingSchedule,
    setWorkingSchedule,
    row,
    bulkWorkingSchedule,
    setBulkWorkingSchedule,
    setRow,
  } = useResturant();

  const { mutate, isLoading } = useMutation(
    (e) => updateData("vendor/workingsechdule", e),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsSchedule(false);
          queryClient.invalidateQueries("resturants");
          setRow(null);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleSave = async () => {
    let isError = false;
    await workingSchedule.map((el) => {
      el.workingTime.map((time) => {
        if (time.from == null) {
          isError = true;
        }
      });
    });

    if (isError) {
      message.error("There is Empty Cell !");
    } else {
      let _workingSchedule = JSON.stringify(
        workingSchedule.map((t) => {
          t.workingTime = t.workingTime.map((w) => ({
            from: dayjs(w.from).toDate(),
            to: dayjs(w.to).toDate(),
          }));
          return { ...t };
        })
      );
      //row.BusinessCategoryIDS = row?.businessCategories ? JSON.stringify(row?.businessCategories?.map(el => el?.id)) : "[]";
      let data = {
        id: row.id,
        workingSchedule: _workingSchedule,
      };
      // row.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      mutate(data);
    }
  };

  return (
    <Modal
      title={
        <Title style={{ marginBottom: 0 }} level={5}>
          Working Days
        </Title>
      }
      className="app-modal"
      centered
      visible={isSchedual}
      onCancel={() => setIsSchedule(false)}
      footer={false}
      destroyOnClose={true}
      width={"90vw"}
      bodyStyle={{ paddingTop: 10, minHeight: "75vh" }}
      //maskClosable={false}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "0px 10px 0px 10px",
        }}
      >
        <Title style={{ marginBottom: 0, color: "gray" }} level={5}>
          Bulk Update Working Days
        </Title>
        <div
          style={{
            padding: "10px",
            width: "250px",
          }}
        >
          <RangePicker
            value={bulkWorkingSchedule}
            onChange={(val) => {
              setBulkWorkingSchedule(val);
            }}
            bordered={true}
            format={"hh:mm A"}
            size="small"
            allowClear={true}
            style={{
              width: "250px",
              padding: "8px",
            }}
          />
        </div>
      </div>

      <div
        style={{
          borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "2px",
        }}
      />
      <CustomeSchedule
        value={workingSchedule}
        onChange={(val) => {
          setWorkingSchedule(val);
        }}
        fromInit={12}
        type={"vendor"}
      />

      <Button
        loading={isLoading}
        style={{ position: "absolute", bottom: 35, left: 35 }}
        type="primary"
        onClick={handleSave}
        icon={<SaveOutlined />}
      >
        Save
      </Button>
    </Modal>
  );
};
