import React, { useState } from "react";
import { Table, Typography, Button, Space, Tooltip, Pagination, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import {  getFiles, pageSize } from "../../api";
import { useQuery } from "react-query";
import dayjs from "dayjs";

const copy = require("clipboard-copy");
const { Link } = Typography;


// Define table columns
const columns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Link",
    dataIndex: "link",
    key: "link",
    render: (text) => (
      <Space>
        <Link style={{ textDecoration: "underline" }}>{text}</Link>
        <Tooltip placement="top" title="copy link">
          <Button
            size="small"
            type="text"
            icon={<CopyOutlined />}
            aria-label="Copy link"
            onClick={()=>copyLink(text)}
          />
        </Tooltip>
      </Space>
    ),
  },
  {
    title: "File Name",
    dataIndex: "fileName",
    key: "fileName",
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => {
      return <>{dayjs(date).format("YYYY-MM-DD hh:mm A")}</>;
    },
  },
  {
    title: "Created By",
    dataIndex: "created_by",
    key: "created_by",
  },
];

const copyLink = (val) => {
  let successPromise = copy(val);
  if (successPromise) {
    message.success("Copy to clipboard");
  } else {
    message.error("Faild Copy !");
  }
};

// Main component
export const PuerTable = () => {
  const [page, setpage] = useState(1)
  // Fetch data using React Query
  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["upload",page], getFiles, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  // Handle errors
  if (error) {
    return <Typography.Text type="danger">Error fetching data</Typography.Text>;
  }

  return (
  <div>
      <Table
      columns={columns}
      dataSource={data ? data?.data?.records : []} // Use the correct data path
      size="small"
      rowKey={(record) => record.id}
      pagination={false}
      loading={loading} // Show loading state
    />

    <br/>
    <Pagination
    onChange={(val) => setpage(val)}
    current={page}
    defaultPageSize={pageSize}
    total={data && data?.data?.total}
    showSizeChanger={false}
   
  />
  </div>
  );
};