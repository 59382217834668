import { useEffect, useState } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import dayjs from "dayjs";
import { getProductGroup, removeProductGroup, pageSize } from "../../api";
import { useProductGroup } from "./store";
import { useApp } from "../../store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const [page, setPage] = useState(1);
  const {
    queryKey,
    setIsCreateModal,
    setName,
    setId,
    setPriority,
    setInternalName,
    setInternalProductId,
    setSize,
  } = useProductGroup();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setName(row.name);
    setId(row.id);
    setPriority(row.priority);
    setInternalProductId(row.internal_product_groups_id);
    setInternalName(row.internal_name);
    setSize(row.size);
    setIsCreateModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },

    {
      title: "Item Size",
      dataIndex: "size",
      key: "size",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (val) => (
        <Tag color="magenta">{Number(val).toLocaleString()}</Tag>
      ),
    },
    {
      title: "Internal Product Category",
      dataIndex: "internal_name",
      key: "internal_name",
      render: (text) => (
        <Text style={{ fontWeight: text ? "bold" : "normal" }}>
          {text || "لا يوجد"}
        </Text>
      ),
    },
    {
      title: "Creation Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("YYYY-MM-DD hh:mm A")}</Text>
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("PRODUCT_GROUP_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("PRODUCT_GROUP_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              //onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation(removeProductGroup, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("productGroup");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["productGroup", queryKey, page], getProductGroup, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
