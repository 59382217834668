import { message } from "antd";
import readXlsxFile from "read-excel-file";

export const handleFileUpload = async (csvFile) => {
  try {
    const sheets = await readXlsxFile(csvFile, { getSheets: true });

    if (sheets.length !== 3) {
      message.error("The file must contain exactly 3 sheets.");
      return;
    }

    const vendorData = {
      products: [],
      toppingsGroup: [],
      toppings: [],
    };

    const sheetProcessors = {
      products: (rows) => {
        rows.slice(1).forEach((row, idx) => {
          vendorData.products.push({
            name: row[0] || "",
            description: row[1] || "",
            price: parseFloat(row[2]) || 0,
            productGroupId: row[3] ? parseInt(row[3]) : 0,
            preparationTime: row[4] ? parseInt(row[4]) : 0,
            priority: parseInt(row[5]) || 0,
            sync: row[6] || false,
          });
        });
      },

      toppingsGroup: (rows) => {
        rows.slice(1).forEach((row, idx) => {
          vendorData.toppingsGroup.push({
            name: row[0] || "",
            multiSelect: row[1] || false,
            priority: row[2] || 0,
            productName: row[3] || "",
          });
        });
      },

      toppings: (rows) => {
        rows.slice(1).forEach((row, idx) => {
          vendorData.toppings.push({
            name: row[0] || "",
            description: row[2] || "",
            priority: row[4] || 0,
            price: row[1] || 0,
            groupName: row[3] || "",
            productName: row[5] || "",
          });
        });
      },
    };

    for (const [index, sheet] of sheets.entries()) {
      try {
        const rows = await readXlsxFile(csvFile, { sheet: sheet.name });
        const processor = Object.keys(sheetProcessors)[index];

        if (processor) {
          sheetProcessors[processor](rows);
        }
      } catch (error) {
        message.error(`Error reading data from ${sheet.name}`); // Show an error message
      }
    }

    return vendorData;
  } catch (error) {
    console.error("Error reading the file:", error);
    message.error("An error occurred while reading the file.");
  }
};
