import { useState, useEffect } from "react";
import { Select, Spin, DatePicker, Space } from "antd";
import { useVendorPerformanceScore } from "./store";
import { getRegions, getRestaurantsNames } from "../../api";
import { useQuery } from "react-query";
import "../../App.css";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

const date = new Date();

export default function VendorPerformanceScoreFilters() {
  const {
    setPage,

    vendorID,
    setVendorID,

    cityID,
    setCityID,

    from,
    setFrom,

    to,
    setTo,
  } = useVendorPerformanceScore();

  const fromDateFormated = moment(date.setHours(8, 0, 0, 0)).format(
    "YYYY-MM-DD HH:mm"
  );
  const toDateFormated = moment(date.setHours(23, 59, 59, 0)).format(
    "YYYY-MM-DD HH:mm"
  );

  // SEARCH STATES
  const [resturantSearch, setResturantSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");

  // GENERAL STATES
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading: isResturantLoading,
    data: resturants,
    refetch: restRefetch,
  } = useQuery(["resturants", resturantSearch, 1], getRestaurantsNames, {
    enabled: !!resturantSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isCityLoading,
    data: cities,
    refetch: citiesRefectch,
  } = useQuery(["regions", "", citySearch, 1], getRegions, {
    enabled: !!citySearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let obj = {
      cityID: Number(searchParams.get("cityID")) || null,
      vendorID: Number(searchParams.get("vendorID")) || null,
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setCityID(obj.cityID);
    setFrom(obj.from);
    setTo(obj.to);
    setVendorID(obj.vendorID);
    setPage(obj.page_num);
  }, [
    searchParams.get("cityID"),
    searchParams.get("vendorID"),
    searchParams.get("from"),
    searchParams.get("to"),
    searchParams.get("page_num"),
  ]);

  useEffect(() => {
    if (!searchParams.get("from")) {
      searchParams.set("from", JSON.stringify(fromDateFormated));
      searchParams.set("to", JSON.stringify(toDateFormated));
      setSearchParams(searchParams);
    }
  }, [searchParams.get("from")]);

  const SelectTimeDate = (value, dateString) => {
    let from = searchParams.get("from")
      ? JSON.stringify(dateString[0])
      : JSON.stringify(fromDateFormated);
    let to = searchParams.get("from")
      ? JSON.stringify(dateString[1])
      : JSON.stringify(fromDateFormated);

    if (value) {
      setFrom(from);
      setTo(to);
      searchParams.set("from", from);
      searchParams.set("to", to);
      searchParams.set("page_num", 1);
      setSearchParams(searchParams);
    }
    // else {
    //     setFrom(null);
    //     setTo(null);
    //     makeFilter({ from: JSON.stringify(fromDateFormated), to: JSON.stringify(toDateFormated),
    //         vendorID, voucherID, cityID, clientID, status, page_num: 1 });
    // }
  };

  return (
    <Space wrap>
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isResturantLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val !== undefined) {
            setVendorID(val);
            searchParams.set("vendorID", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setVendorID(null);
          setResturantSearch(null);
          searchParams.delete("vendorID");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setResturantSearch(val)}
        placeholder="Select Vendors"
        style={{ width: "100%", maxWidth: 160 }}
        value={vendorID}
        onClick={() => restRefetch()}
      >
        {resturants &&
          resturants.success &&
          resturants?.data?.records?.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isCityLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val !== undefined) {
            setCityID(val);
            searchParams.set("cityID", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setCityID(null);
          setCitySearch(null);
          searchParams.delete("cityID");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setCitySearch(val)}
        onClick={() => citiesRefectch()}
        placeholder="Select City"
        style={{ width: "100%", maxWidth: 160 }}
        value={cityID}
      >
        {cities &&
          cities.success &&
          cities.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Space direction="vertical" size={5}>
        <RangePicker
          showTime={{
            format: "HH:mm",
          }}
          value={
            from
              ? [
                moment(from, "YYYY-MM-DD HH:mm"),
                moment(to, "YYYY-MM-DD HH:mm"),
              ]
              : [moment(fromDateFormated), moment(toDateFormated)]
          }
          defaultValue={[moment(fromDateFormated), moment(toDateFormated)]}
          format="YYYY-MM-DD HH:mm"
          onChange={SelectTimeDate}
        />
      </Space>
    </Space>
  );
}
