import { ClockCircleOutlined } from "@ant-design/icons";
import { Typography, Space, Select, message, Modal, Input } from "antd";
import { useOrderView } from "./store";
import { useState } from "react";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import {
  changeOrderStatus,
  getOrderStatus,
  getDeclineReason,
  changeDeclineReason,
} from "../../api";
import { queryClient } from "../../queryClient";
import dayjs from "dayjs";
import authFunc from "../../helper/auth";

const { Text, Title } = Typography;
const { Option } = Select;

export const StatusHistory = () => {
  const {
    order,
    setIsCreateCancelModal,
    isSelectReason,
    setSelectedReason,
    selectedReason,
  } = useOrderView();
  const [history, setHistoy] = useState([]);
  const [otp, setOtp] = useState("");

  const { isLoading: statusLoading, data: orderStatus } = useQuery(
    ["orderStatus", "", 1, 100],
    getOrderStatus,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!order,
    }
  );

  const { isLoading: reasonsLoading, data: reasonData } = useQuery(
    ["declineReasons", "", 1, 500],
    getDeclineReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  useEffect(() => {
    if (order && order?.order) {
      setHistoy(JSON.parse(order?.order?.status)?.reverse());
    }
  }, [order]);

  // const isDev = process.env.REACT_APP_IS_DEV === "true";

  const statusRender = () => {
    // let statusObj = history[0];
    return (
      <>
        <Select
          //disabled={changeStatusLoading || statusObj?.status === "DELIVERED" || statusObj?.status === "BACKOFFICE_REJECTED" ? true : false}
          dropdownMatchSelectWidth={false}
          size="small"
          value={selectedReason}
          onChange={(val) => handleChangeStatus(val)}
          bordered={false}
          placeholder="Change Status"
          loading={statusLoading}
          style={{ color: "#0000ff", width: "100%" }}
        >
          {orderStatus &&
            orderStatus?.data?.records?.map(
              // add this condition 'el.userType === "BACKOFFICE_USER" &&' after '=>' bellow to show only back office status
              (el, index) => (
                // (el.userType === "BACKOFFICE_USER" || isDev) &&
                // el.code !== "BACKOFFICE_REJECTED" &&
                // el.code !== statusObj?.status && !history.find(hist=>  hist.status === el.code) &&
                <Option key={index} value={el.code}>
                  {el.arTitle}{" "}
                  <small style={{ color: el?.color }}>( {el.code} )</small>
                  <br />
                  <small style={{ opacity: 0.6 }}>{el.userType}</small>
                </Option>
              )
              // el.userType == "BACKOFFICE_USER" && (
              //   <Option key={index} value={el.code}>
              //     {el.arTitle}
              //   </Option>
              // )
            )}
        </Select>
      </>
    );
  };

  const declineReasonsRender = () => {
    return (
      <>
        <Select
          dropdownMatchSelectWidth={false}
          size="small"
          onChange={(val) => handleChangedeclineReason(val)}
          bordered={false}
          value={delicneReasons?.arTitle}
          placeholder="Change Decline Reason"
          loading={reasonsLoading}
          style={{ color: "#0000ff", width: "100%" }}
        >
          {reasonData &&
            reasonData?.data?.records?.map(
              (el, index) =>
                el.userType === "BACKOFFICE_USER" && (
                  <Option
                    disabled={delicneReasons?.arTitle === el.arTitle}
                    key={el.id}
                    value={el.id}
                  >
                    {el.floating ? "Floating :" + el.arTitle : el.arTitle}
                    <br />
                    <small style={{ opacity: 0.6, fontStyle: "italic" }}>
                      code: {el.code}
                    </small>
                  </Option>
                )
            )}
        </Select>
      </>
    );
  };

  useEffect(() => {
    if (otp.length === 6) {
      let dataForm = {
        id: order?.order?.id,
        status: "DELIVERED",
        otp: otp,
      };
      changeStatusMutate(dataForm);
    }
  }, [otp]);

  const { mutate: changeStatusMutate } = useMutation(changeOrderStatus, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setSelectedReason(null);
        queryClient.invalidateQueries("orderInfo");
        setOtp("");
        Modal.destroyAll();
      } else if (data.err === "invalid OTP") {
        message.error("Invalid OTP, please try again.");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: declineReasonMutate } = useMutation(changeDeclineReason, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);

        queryClient.invalidateQueries("orderInfo");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleChangeStatus = (val) => {
    Modal.confirm({
      content: (
        <div>
          <Title level={5}>Confirm</Title>
          <Text>Do you really want to change the status of the order?</Text>
        </div>
      ),
      onOk: () => {
        let dataForm = {
          id: order?.order?.id,
          status: val,
        };
        setSelectedReason(val);
        if (
          val === "CANCELLED" ||
          val === "BACKOFFICE_REJECTED" ||
          val === "REJECTED"
        ) {
          setIsCreateCancelModal(true);
          if (isSelectReason) {
            changeStatusMutate(dataForm);
          }
        } else {
          if (val === "DELIVERED" && order.order.paymentType === "ONLINE") {
            handleOtpModal(val);
          } else {
            changeStatusMutate(dataForm);
          }
        }
      },
    });
  };

  const handleOtpModal = (val) => {
    Modal.confirm({
      content: (
        <>
          <div>
            <Title level={5}>Confirm with otp </Title>
            {authFunc("ORDER_ONLINE_UPDATE") ? (
              <div style={{ marginTop: "10px" }}>
                <label>Otp</label>
                <Input
                  placeholder="Enter OTP"
                  maxLength={6}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
              </div>
            ) : (
              <>
                <h3>You don't have permission to edit this order</h3>
              </>
            )}
          </div>
        </>
      ),
      onOk: () => {},
    });
  };

  const handleChangedeclineReason = (val) => {
    Modal.confirm({
      content: (
        <div>
          <Title level={5}>Confirm</Title>
          <Text>
            Do you really want to change the Decline Reason of the order?
          </Text>
        </div>
      ),
      onOk: () => {
        let dataForm = {
          id: order?.order?.id,
          declineReasonID: Number(val),
        };
        declineReasonMutate(dataForm);
      },
    });
  };

  //let currentStatus = orderStatus?.data?.records?.find(el=> el.code === history[0]?.status);
  let delicneReasons =
    order?.order?.declineReason && JSON.parse(order?.order?.declineReason);
  return (
    <>
      {order?.order?.declineReason !== "{}" && (
        <>
          <Text> Decline Reason </Text>
          <div className="status-history">
            <section style={{ padding: "10px 8px" }}>
              {declineReasonsRender()}
            </section>
          </div>
        </>
      )}

      <Text>Status History </Text>
      <div className="status-history">
        <section style={{ padding: "10px 8px" }}>{statusRender()}</section>
        {history?.map((el, index) => (
          <section key={index}>
            <span style={{ color: el?.color }}>
              {el.status} -
              <Text style={{ fontStyle: "italic" }} type="secondary">
                {el?.arTitle}
              </Text>
            </span>
            <div className="app-space-between">
              <div>
                by <b> {el?.userName}</b> ({el?.userType})
              </div>
              <Text style={{ fontSize: "10px" }} type="secondary">
                <Space size={4}>
                  {dayjs(el.created_at).format("YYYY-MM-DD hh:mm A")}
                  <ClockCircleOutlined />
                </Space>
              </Text>
            </div>
          </section>
        ))}

        {/* <section className="app-space-between">
          <div>
            <span style={{ color: "orange" }}>PENDING</span> / changed by
            <b> Guest</b> (CLIENT)
          </div>
          <Text type="secondary">
            <Space>
              Jun 29, 2022 <ClockCircleOutlined />
            </Space>
          </Text>
        </section>
        <section className="app-space-between">
          <div>
            <span style={{ color: "green" }}>ACCEPTED</span> / changed by
            <b> Guest</b> (VENDOR)
          </div>
          <Text type="secondary">
            <Space>
              Jun 29, 2022 <ClockCircleOutlined />
            </Space>
          </Text>
        </section>
        <section className="app-space-between">
          <div>
            <span style={{ color: "#0000ff" }}>ACCEPTED</span> / changed by
            <b> Guest</b> (Biker)
          </div>
          <Text type="secondary">
            <Space>
              Jun 29, 2022 <ClockCircleOutlined />
            </Space>
          </Text>
        </section> */}
      </div>
    </>
  );
};
