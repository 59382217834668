import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { creatClient, editClient } from "../../api";
import { useClient } from "./store";
import { useEffect, useState } from "react";

const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [phoneState, setPhoneState] = useState(null);

  const {
    isCreateModal,
    setIsCreateModal,
    firstName,
    lastName,
    username,
    gender,
    setGender,
    id,
    email,
    phone,
    setEmail,
    setFirstName,
    setLastName,
    setPassword,
    setPhone,
    setUsername,
    setId,
  } = useClient();
  const [form] = Form.useForm();

  const resetState = () => {
    setEmail(null);
    setFirstName(null);
    setLastName(null);
    setPassword(null);
    setPhone(null);
    setUsername(null);
    setId(null);
    setGender(null);
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(creatClient, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);

        queryClient.invalidateQueries("clients");
        resetState();
        setIsCreateModal(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editClient,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("clients");
          resetState();
          setIsCreateModal(false);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const phoneValidate = () => {
    const regex = /^07[3-9]\d{8}$/;
    const result = regex.exec(phoneState);
    return result;
  };

  const onFinish = (values) => {
    values.status = true;
    if (id && phoneValidate() && phoneValidate()?.index === 0) {
      values.phone = phoneState;
      values.id = id;
      editMutate(values);
    } else if (phoneValidate() && phoneValidate()?.index === 0) {
      values.phone = phoneState;
      mutate(values);
    } else setPhoneValidation(true);
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        firstName,
        lastName,
        username,
        email,
        phone: phone || '',
        gender,
      });
      setPhoneState(phone); // Set phoneState to the current phone number
    } else {
      resetState();
      form.setFieldsValue({
        gender: "male",
      });
    }
  }, [id, firstName, lastName, username, email, phone, gender]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Client</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={460}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Baly" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Food" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Username"
              name="username"
              //rules={[{ required: true }]}
            >
              <Input placeholder={"baly001"} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={"07**** ****"}
                status={phoneValidation ? "error" : ""}
                maxLength="11"
                minLength="11"
                onChange={(e) => setPhoneState(e.target.value)}
                value={phoneState}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Email" name="email">
              <Input placeholder={"example@baly.iq"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose gender">
                <Option value={"male"}>Male</Option>
                <Option value={"female"}>Female</Option>
              </Select>
            </Form.Item>
          </Col>
          {!id ? (
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input placeholder="******" />
              </Form.Item>
            </Col>
          ) : null}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
