import React, { useRef } from "react";
import { Button, message } from "antd";
import { handleFileUpload } from "../../helper/importCsv";
import { productUpload } from "../../api";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";

const ImportCSVButton = (vendorId) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const formatText = (errorText) => {
    return errorText.split("\n").map((line, index) => (
      <span
        key={index}
        style={{
          display: "block",
          padding: "5px 0",
          fontSize: "14px",
          color: "#FF3C3FFF",
        }}
      >
        {line}
      </span>
    ));
  };

  const { mutate: importData } = useMutation(productUpload, {
    onSuccess: (data) => {
      if (data?.success) {
        message.success("Data uploaded successfully!");
        queryClient.invalidateQueries("products");
        handleReset();
      } else {
        message.error(
          <div
            style={{
              maxWidth: "400px",
              wordWrap: "break-word",
              textAlign: "left",
            }}
          >
            {formatText(data.err)}
          </div>
        );
        handleReset();
      }
    },
  });

  const onFileChange = async (csvFile) => {
    const file = csvFile.target.files[0];
    if (!file) {
      message.error("Please select a valid Excel file.");
      return;
    }
    const data = await handleFileUpload(file);

    const uploadData = {
      ...data,
      vendorID: parseFloat(vendorId.vendorId),
    };

    importData(uploadData);
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".csv, .xlsx"
        onChange={onFileChange}
      />
      <Button onClick={handleButtonClick} type="primary">
        Import CSV
      </Button>
    </div>
  );
};

export default ImportCSVButton;
