// const LIVE_URL = "http://localhost:9001/api";
// const LIVE_URL = "https://back.baly.dev/api";
// const BIKER_URL = "https://box.baly.dev/api";

import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { useApp } from "../src/store/useApp";
import { queryClient } from "./queryClient";

const LIVE_URL =
  process.env.NODE_ENV === "production" ? "/api" : "https://back.baly.dev/api";

const BIKER_URL =
  process.env.REACT_APP_IS_DEV === "true"
    ? "https://box.baly.dev/api"
    : "https://box.food.baly.iq/api";

export const pageSize = 10;
let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
//const LIVE_URL = "https://647b-37-237-76-45.ngrok.io/api";

export const refresh = (cb) => {
  try {
    let bo_refresh_token = localStorage.getItem("bo_refresh_token");
    if (bo_refresh_token) {
      fetch(`${LIVE_URL}/auth/refresh?tz=${tz}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bo_refresh_token}`,
        },
      })
        .then((res) => res.json())
        .then((resp) => {
          cb(resp, null);
        })
        .catch((e) => cb(null, e));
    }
  } catch (err) {
    cb(null, err);
  }
};

const apiCall = async (
  method,
  endpoint,
  data,
  useFormData = false,
  isBiker = false
) => {
  try {
    let urlString = `${isBiker ? BIKER_URL : LIVE_URL}/${endpoint}`;
    // let token = localStorage.getItem("bo_token");

    // Append the 'tz' parameter to the URL
    urlString += `?tz=${tz}`;

    // should check if the token and refresh token is expired
    const token = localStorage.getItem("bo_token");
    const refreshToken = localStorage.getItem("bo_refresh_token");

    const decodedToken = jwtDecode(token);
    const decodedRefreshToken = jwtDecode(refreshToken);

    // check if no token or refresh token found
    if (!token || !refreshToken) {
      useApp.getState().setIsLogin(false);
      localStorage.clear();
      return;
    }

    // Append query parameters to the URL
    if (method === "GET" || method === "DELETE") {
      const queryParams = Object.keys(data)
        .map((key) => `${key}=${data[key]}`)
        .join("&");

      if (queryParams) {
        urlString += `&${queryParams}`;
      }
    }

    let headers = {
      Authorization: `Bearer ${token}`,
    };

    // Set the content type header based on useFormData
    let body = null;

    if (useFormData) {
      var formdata = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formdata.append(key, value);
      });
      body = formdata;
    } else {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    // For GET requests, we don't include a request body
    const requestOptions = {
      method,
      mode: "cors",
      headers,
    };

    if (method !== "GET") {
      requestOptions.body = body;
    }

    // check if refresh token is expired
    if (decodedRefreshToken.exp < Date.now() / 1000) {
      useApp.getState().setIsLogin(false);
      localStorage.clear();
      return;
    }

    // check if token is expired
    if (decodedToken.exp < Date.now() / 1000) {
      // should refresh token here
      refresh(async (refreshResponse) => {
        localStorage.setItem("bo_token", refreshResponse?.data?.token);
        localStorage.setItem(
          "bo_refresh_token",
          refreshResponse?.data?.refreshToken
        );

        queryClient.invalidateQueries();
        // Retrying the original request
        const response = await fetch(urlString, {
          ...requestOptions,
          headers: {
            Authorization: `Bearer ${refreshResponse?.data?.token}`,
          },
        });

        return await response.json();
      });
    } else {
      // Make the request
      const response = await fetch(urlString, requestOptions);

      return await response.json();
    }
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const Req = (method, prefix, data) => {
  let headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
  };

  return fetch(`${LIVE_URL}/${prefix}`, {
    method,
    headers,
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((e) => ({ success: false, err: e }));
};

export const findGov = (query, cb) => {
  fetch(
    `https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1&limit=1`
  )
    .then((res) => res.json())
    .then((jsonResults) => cb(jsonResults, null))
    .catch((e) => cb(null, e));
};

export const login = ({ username, password }, cb) => {
  fetch(`${LIVE_URL}/auth/login?tz=${tz}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
  })
    .then((res) => res.json())
    .then((jsonResults) => cb(jsonResults, null))
    .catch((e) => cb(null, e));
};
export const loginOtp = ({ username, password, otp }, cb) => {
  fetch(`${LIVE_URL}/auth/otp?tz=${tz}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      otp,
    }),
  })
    .then((res) => res.json())
    .then((jsonResults) => cb(jsonResults, null))
    .catch((e) => cb(null, e));
};

export const resetPassword = (data, cb) => {
  fetch(`${LIVE_URL}/auth/backoffice/updateExpiredPassword?tz=${tz}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((jsonResults) => cb(jsonResults, null))
    .catch((e) => cb(null, e));
};

export const createGovs = async (data) =>
  await apiCall("POST", "v1/province", data);

export const getQrCode = async () => await apiCall("PUT", "v1/users/secret");

export const saveQrCode = async ({ twofaEnabled, otp }) =>
  await apiCall("PUT", "backoffice/users/fastatus", {
    twofaEnabled,
    otp,
  });

export const getGovs = async (e) =>
  await apiCall("GET", "v1/province", {
    page_num: 1,
    page_size: 1000,
    term: e.queryKey[1] || "",
  });

export const removeGovs = async (id) =>
  await apiCall("DELETE", "v1/province", { id });

export const getZones = async (e) =>
  await apiCall("GET", "v1/zone", {
    page_size: pageSize,
    term: e.queryKey[1] || "",
    page_num: e.queryKey[2] || 1,
  });

export const createZone = async (data) =>
  await apiCall("POST", "v1/zone", data);

export const editZone = async (data) => await apiCall("PUT", "v1/zone", data);

export const removeZone = async (id) =>
  await apiCall("DELETE", "v1/zone", { id });

export const sendToBiker = async (data) =>
  await apiCall("POST", "v1/biker", data, false, true);

export const cancelHybridPay = async (data) =>
  await apiCall("POST", "v1/invoice/bo", data, false, true);

export const zeroBikerTotal = async (data) =>
  await apiCall("PUT", "v1/biker", data, false, false);

export const removeRegion = async (id) =>
  await apiCall("DELETE", "v1/city", { id });

export const getRegions = async (e) =>
  await apiCall("GET", "v1/city", {
    page_size: e.queryKey[4] || pageSize,
    page_num: e.queryKey[3] || 1,
    term: e.queryKey[2] || "",
    provinceID: e.queryKey[1] || "",
    zoneID: e.queryKey[5] || "",
  });

export const createRegion = async (data) =>
  await apiCall("POST", "v1/city", data);

export const editRegion = async (data) => await apiCall("PUT", "v1/city", data);

export const getPayment = async (e) =>
  await apiCall("GET", "v1/orders/payment", {
    page_size: pageSize,
    page_num: e.queryKey[1],
    from: e.queryKey[2] ? dayjs(JSON.parse(e.queryKey[2])).toISOString() : "",
    to: e.queryKey[3] ? dayjs(JSON.parse(e.queryKey[3])).toISOString() : "",
    vendorID: e.queryKey[4] || "",
  });

export const createPayment = async (data) =>
  await apiCall("POST", "v1/payment", data);

export const createChain = async (data) =>
  await apiCall("POST", "v1/vendorchain", data, true);

export const editChain = async (data) =>
  await apiCall("PUT", "v1/vendorchain", data);

export const editChainVendor = async (data) =>
  await apiCall("PUT", "v1/vendorchain/vendors", data);

export const getChains = async (e) =>
  await apiCall("GET", "v1/vendorchain", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const removeChain = async (id) =>
  await apiCall("DELETE", "v1/vendorchain", { id });

export const getStatus = async (e) =>
  await apiCall("GET", "v1/statuscode", {
    provinceID: e.queryKey[1] || "",
  });

export const getUsers = async (e) =>
  await apiCall("GET", "auth/users", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const removeUser = async (id) =>
  await apiCall("DELETE", "backoffice/users", { id });

export const createUser = async (data) =>
  await apiCall("POST", "auth/users", data);

export const editUser = async (data) =>
  await apiCall("PUT", "backoffice/users", data);

export const resetUser = async (data) =>
  await apiCall("PUT", "backoffice/users/reset", data);

export const createProduct = async (data) =>
  await apiCall("POST", "v1/product", data, true);

export const editProduct = async (data) =>
  await apiCall("PUT", "v2/product", data);

export const removeProduct = async ({ id, vendorID, sync }) =>
  await apiCall("DELETE", "v1/product", {
    id,
    vendorID,
    sync,
  });

export const getProducts = async (e) =>
  await apiCall("GET", "v1/product", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    term: e.queryKey[2] || "",
    productGroupID: e.queryKey[4] || "",
    vendorID: e.queryKey[1] || "",
  });

export const getProductsWithDiscount = async (e) =>
  await apiCall("GET", "v3/productdiscount", {
    page_size: e.queryKey[4] || 1000,
    page_num: e.queryKey[3] || 1,
    term: e.queryKey[2] || "",
    vendorID: e.queryKey[1] || "",
  });

export const getServiceFeeByVendor = async (e) =>
  await apiCall("GET", "v1/servicefee/byvendorid", {
    vendorID: e.queryKey[1],
  });

export const editVendorStatus = async (data) =>
  await apiCall("PUT", "v2/vendor/statusCode", data);

export const createVendors = async (data) =>
  await apiCall("POST", "v1/vendoruser", data);

export const editVendors = async (data) =>
  await apiCall("PUT", "v1/vendoruser", data);

export const updateVendorStatus = async (data) =>
  await apiCall("PUT", "v2/vendor/status", data);

export const updateVoucherSettingsStatus = async (data) =>
  await apiCall("PUT", "v1/voucherSettings/status", data);

export const resetVendor = async (data) =>
  await apiCall("PUT", "v1/vendoruser/reset", data);

export const getVendors = async (e) =>
  await apiCall("GET", "v1/vendoruser", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    vendorID: e.queryKey[3] || "",
  });

export const removeVendors = async (id) =>
  await apiCall("DELETE", "v1/vendoruser", { id });

export const createCategory = async (data) =>
  await apiCall("POST", "v1/businesscategory", data, true);

export const editCategory = async (data) =>
  await apiCall("PUT", "v1/businesscategory", data, true);

export const checkVoucher = async (data) =>
  await apiCall("PUT", "v1/orders/check", data);

export const getCategories = async (e) =>
  await apiCall("GET", "v1/businesscategory", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });
export const updateCategory = async (data) =>
  await apiCall("PUT", "v1/businesscategory/searchable", data, false);

export const removeCategory = async (id) =>
  await apiCall("DELETE", "v1/businesscategory", { id });

// VOUCHER SETTINGS
export const getVoucherSettings = async (e) =>
  await apiCall("GET", "v1/voucherSettings", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[4] || 1,
    term: e.queryKey[1] || "",
    type: e.queryKey[2] || "",
  });

export const editVoucherSettings = async (data) =>
  await apiCall("PUT", "v1/voucherSettings", data);

export const removeVoucherSettings = async (id) =>
  await apiCall("DELETE", "v1/voucherSettings", { id });

export const createVoucherSettings = async (data) =>
  await apiCall("POST", "v1/voucherSettings", data);

export const ChangeHybridPaymentStatus = async (data) =>
  await apiCall("PUT", `v1/${data.route}/hybridpay`, data.paymentObject);

export const getPwaSettings = async (e) =>
  await apiCall("GET", "v1/setting", {
    term: e.queryKey[1] || "",
  });

export const editPwaSettings = async (data) =>
  await apiCall("PUT", "v1/setting", data);

export const removePwaSettings = async (id) =>
  await apiCall("DELETE", "v1/setting", { id });

export const createPwaSettings = async (data) =>
  await apiCall("POST", "v1/setting", data);

export const createApologyMessage = async (data) =>
  await apiCall("POST", "v1/voucherSettings/apologyvoucher/", data);

export const createStatusCode = async (data) =>
  await apiCall("POST", "v1/statuscode", data);

export const editStatusCode = async (data) =>
  await apiCall("PUT", "v1/statuscode", data);

export const getReferralCodes = async (e) =>
  await apiCall("GET", "v1/referalcode", {
    page_size: pageSize,
    page_num: e.queryKey[1],
    term: e.queryKey[2] || "",
    from: e.queryKey[3] ? dayjs(JSON.parse(e.queryKey[3])).toISOString() : "",
    to: e.queryKey[4] ? dayjs(JSON.parse(e.queryKey[4])).toISOString() : "",
    clientID: e.queryKey[5] || "",
  });

export const getWeightsLogs = async (e) =>
  await apiCall("GET", "v1/score/weights/history", {
    page_size: pageSize,
    page_num: e.queryKey[1],
  });

export const getVendorLog = async (e) =>
  await apiCall("GET", "v1/history", {
    page_size: pageSize,
    page_num: e.queryKey[1],
    term: e.queryKey[2] || "",
    from: e.queryKey[3] ? dayjs(JSON.parse(e.queryKey[3])).toISOString() : "",
    to: e.queryKey[4] ? dayjs(JSON.parse(e.queryKey[4])).toISOString() : "",
  });

export const getReferralSettings = async (e) =>
  await apiCall("GET", "v1/referrSetting", {
    page_size: pageSize,
    page_num: e.queryKey[1],
    term: e.queryKey[2],
  });

export const removeReferralSettings = async (id) =>
  await apiCall("DELETE", "v1/referrSetting", { id });

// REFERRAL SETTINGS //
export const createReferralSettings = async (data) =>
  await apiCall("POST", "v1/referrSetting", data);

export const editReferralSettings = async (data) =>
  await apiCall("PUT", "v1/referrSetting", data);

export const editReferralSettingsStatus = async (data) =>
  await apiCall("PUT", "v1/referrSetting/status", data);

// APOLOGY SETTINGS //
export const createApologySettings = async (data) =>
  await apiCall("POST", "v1/apologySettings", data);

export const editApologySettings = async (data) =>
  await apiCall("PUT", "v1/apologySettings", data);

export const getApologySettings = async (e) =>
  await apiCall("GET", "v1/apologySettings", {
    page_size: pageSize,
    page_num: e.queryKey[1],
    term: e.queryKey[2],
  });

export const editApologySettingsStatus = async (data) =>
  await apiCall("PUT", "v1/apologySettings/status", data);

export const removeApologySettings = async (id) =>
  await apiCall("DELETE", "v1/apologySettings", { id });

export const getVmsSettings = async (e) =>
  await apiCall("GET", "v1/vmsOtaConfig", {
    page_size: pageSize || e.queryKey[3],
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const editVmsSettings = async (data) =>
  await apiCall("PUT", "v1/vmsOtaConfig", data);

export const createVmsSettings = async (data) =>
  await apiCall("POST", "v1/vmsOtaConfig", data, true);

export const removeVmsSettings = async (id) =>
  await apiCall("DELETE", "v1/vmsOtaConfig", { id });

export const getStatusCode = async (e) =>
  await apiCall("GET", "v1/statuscode", {
    term: e.queryKey[1] || "",
  });

// CREATE BLOCK REASON
export const createBlockReason = async (data) =>
  await apiCall("POST", "v1/blockingReasons", data);

export const getBlockReason = async (e) =>
  await apiCall("GET", "v1/blockingReasons", {
    page_size: pageSize || 10,
    term: e.queryKey[1] || "",
    page_num: e.queryKey[2] || 1,
  });

export const removeBlockReason = async (id) =>
  await apiCall("DELETE", "v1/blockingReasons", { id });

export const editBlockReason = async (data) =>
  await apiCall("PUT", "v1/blockingReasons", data);

export const blockUser = async (data) =>
  await apiCall("POST", "v1/blockuser", data);

export const removeStatusCode = async (id) =>
  await apiCall("DELETE", "v1/statuscode", { id });

export const createProductGroup = async (data) =>
  await apiCall("POST", "v1/productgroup", data);

export const editProductGroup = async (data) =>
  await apiCall("PUT", "v1/productgroup", data);

export const editProductDisable = async (data) =>
  await apiCall("PUT", "v2/product/disable", data);

export const editProductSchedule = async (path, data) =>
  await apiCall("PUT", `v1/${path}`, data);

export const getProductGroup = async (e) =>
  await apiCall("GET", "v1/productgroup", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const getProductGroupByVendor = async (e) =>
  await apiCall("GET", "v1/productgroup/byVendorID", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[2] || 1,
    vendorID: e.queryKey[1],
  });

export const getValidVoucher = async (e) =>
  await apiCall("GET", "v1/vouchers/valid", {
    clientID: e.queryKey[1],
    platform: e.queryKey[2],
    page_size: 50,
    page_num: 1,
  });

export const removeProductGroup = async (id) =>
  await apiCall("DELETE", "v1/productgroup", { id });

export const createResturant = async (data) =>
  await apiCall("POST", "v1/vendor", data, true);

export const editeResturant = async (data) =>
  await apiCall("PUT", "v1/vendor", data);

export const getResturants = async (e) =>
  await apiCall("GET", "v1/vendor", {
    page_size: e.queryKey[4] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    provinceID: e.queryKey[3] || "",
    cityID: e.queryKey[5] || "",
    active: e.queryKey[6] || "",
    statusCodeID: e.queryKey[7] || "",
    businessCategoryIDS: e.queryKey[8] || "",
    searchRating: e.queryKey[9] || "",
    asc: e.queryKey[10] || "",
  });
export const getRestaurantsNames = async (e) =>
  await apiCall("GET", "v1/vendor/names", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    cityID: e.queryKey[4] || "",
  });

export const getResturantsByLatLon = async (e) =>
  await apiCall(
    "GET",
    // "v3/vendor/bycity",
    "v1/vendor/available",
    {
      page_size: 18,
      page_num: 1,
      lat: e.queryKey[1],
      lon: e.queryKey[2],
      term: e.queryKey[3],
    }
  );

export const getVendorsOnMap = async (e) =>
  await apiCall("GET", "v1/vendor/map", {
    lat: e.queryKey[1] || "",
    lon: e.queryKey[2] || "",
  });

export const createContract = async (data) =>
  await apiCall("POST", "v1/vendor/contract", data, true);

export const editeContract = async (data) =>
  await apiCall("PUT", "v1/vendor/contract", data, true);

export const getContracts = async (e) =>
  await apiCall("GET", "v1/vendor/contract", {
    page_size: 1000,
    page_num: 1,
    term: e.queryKey[2] || "",
    vendorID: e.queryKey[1] || "",
  });

export const removeContract = async (id) =>
  await apiCall("DELETE", "v1/vendor/contract", { id });

export const getResturantOne = async (e) =>
  await apiCall("GET", "v1/vendor/search", { id: e.queryKey[1] || "" });

export const removeResturant = async (id) =>
  await apiCall("DELETE", "v1/vendor", { id });

export const editeProfile = async (data) =>
  await apiCall("PUT", "backoffice/profile", data);

export const resetProfile = async (data) =>
  await apiCall("PUT", "backoffice/profile/reset", data);

export const createBanner = async (data) =>
  await apiCall("POST", "v1/appbanners", data, true);

export const editeBanner = async (data) =>
  await apiCall("PUT", "v2/appbanners", data);

export const editeBanneStatus = async (data) =>
  await apiCall("PUT", "v1/appbanners/status", data);

export const vendorToBanner = async (data) =>
  await apiCall("PUT", "v1/appbanners/vendors", data);

export const bannersToBanner = async (data) =>
  await apiCall("PUT", "v1/appbanners/banners", data);

export const DeleteFromAppBanner = async (name, data) =>
  await apiCall("DELETE", `v1/appbanners/${name}`, data);

export const deleteAllClientFromAppBanner = async (path, data) =>
  await apiCall("DELETE", `v1/appbanners/${path}`, data);

export const categoryToBanner = async (data) =>
  await apiCall("PUT", "v1/appbanners/categories", data);

export const usersToBanner = async (data) =>
  await apiCall("PUT", "v1/appbanners/users", data);

export const AddToBanners = async (name, data) =>
  await apiCall("PUT", `v1/appbanners/${name}`, data);

export const getBanners = async (e) =>
  await apiCall("GET", "v1/appbanners", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    type: e.queryKey[1],
    term: e.queryKey[2] || "",
  });

export const getBannersForSlider = async (e) =>
  await apiCall("GET", "v1/appbanners/forslider", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    term: e.queryKey[1] || "",
  });

export const getAppbannersVendor = async (e) =>
  await apiCall("GET", "v1/appbanners/vendors", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    type: e.queryKey[1],
    id: e.queryKey[2] || "",
  });

export const getAppbannersCategory = async (e) =>
  await apiCall("GET", "v1/appbanners/categories", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    type: e.queryKey[1],
    id: e.queryKey[2] || "",
  });

export const getAppBannersBaner = async (e) =>
  await apiCall("GET", "v1/appbanners/banners", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    id: e.queryKey[1] || "",
  });

export const removeBanner = async (id) =>
  await apiCall("DELETE", "v1/appbanners", { id });

export const creatToken = async (data) =>
  await apiCall("POST", "v1/tokens", data);

export const getTokens = async (e) =>
  await apiCall("GET", "v1/tokens", {
    page_num: e.queryKey[1],
  });

export const updateToken = async (data) =>
  await apiCall("PUT", "v1/tokens", data);

export const updateTokenStatus = async (data) =>
  await apiCall("PUT", "v1/tokens/status", data);

export const deleteTokens = async (id) =>
  await apiCall("DELETE", "v1/tokens", { id });

export const creatClient = async (data) =>
  await apiCall("POST", "v1/clients", data);

export const editClient = async (data) =>
  await apiCall("PUT", "v1/clients", data);

export const resetClient = async (data) =>
  await apiCall("PUT", "v1/clients/reset", data);

export const getClients = async (e) =>
  await apiCall("GET", "v4/clients", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    ...e.queryKey[4],
  });

export const getClientsVoucher = async (e) =>
  await apiCall("GET", "v1/clients/voucher", {
    id: e.queryKey[3],
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const getClientsAppbanner = async (e) =>
  await apiCall("GET", "v1/appbanners/users", {
    id: e.queryKey[3],
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const getVendorVoucher = async (e) =>
  await apiCall("GET", "v1/vendor/voucher", {
    id: e.queryKey[3],
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const removeClient = async (id) =>
  await apiCall("DELETE", "v1/clients", { id });

export const creatVoucher = async (data) =>
  await apiCall("POST", "v1/vouchers", data);

export const creatBulkVoucher = async (data) =>
  await apiCall("POST", "v1/vouchers/batch", data);

export const editVoucher = async (data) =>
  await apiCall("PUT", "v1/vouchers", data);

export const editVoucherStatus = async (data) =>
  await apiCall("PUT", "v1/vouchers/status", data);

export const getVouchers = async (e) =>
  await apiCall("GET", "v1/vouchers", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    active: e.queryKey[3] || "",
    from: e.queryKey[4] ? dayjs(JSON.parse(e.queryKey[4])).toISOString() : "",
    to: e.queryKey[5] ? dayjs(JSON.parse(e.queryKey[5])).toISOString() : "",
  });

export const getCompoundVouchers = async (e) =>
  await apiCall("GET", "v1/vouchers/compound", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    active: e.queryKey[3] || "",
    from: e.queryKey[4] ? dayjs(JSON.parse(e.queryKey[4])).toISOString() : "",
    to: e.queryKey[5] ? dayjs(JSON.parse(e.queryKey[5])).toISOString() : "",
  });

export const getBulkVouchers = async (e) =>
  await apiCall("GET", "v1/vouchers/batch", {
    page_size: e.queryKey[4] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    ...e.queryKey[3],
  });

export const getVoucher = async (id) =>
  await apiCall("GET", `v1/vouchers/search`, { id });

// DISCOUNT CAP
export const getDiscountCap = async (e) =>
  await apiCall("GET", "v1/discountcap", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    from: e.queryKey[3] ? dayjs(JSON.parse(e.queryKey[3])).toISOString() : "",
    to: e.queryKey[4] ? dayjs(JSON.parse(e.queryKey[4])).toISOString() : "",
    status: e.queryKey[5] || "",
  });

export const createDiscountCap = async (data) =>
  await apiCall("POST", "v1/discountcap", data);

export const removeDiscountCap = async (id) =>
  await apiCall("DELETE", "v1/discountcap", { id });

export const editDiscountCapStatus = async (data) =>
  await apiCall("PUT", "v1/discountcap/status", data);

export const editDiscountCap = async (data) =>
  await apiCall("PUT", "v1/discountcap", data);

// // // // // // // // // // // // //

export const getUsedVouchers = async (e) =>
  await apiCall("GET", "v1/vouchers/used", {
    clientID: e.queryKey[1],
    page_size: 10,
    page_num: 1,
  });

export const removeVoucher = async (id) =>
  await apiCall("DELETE", "v1/vouchers", { id });

export const AddToVoucher = async (name, data) =>
  await apiCall("PUT", `v1/vouchers/${name}`, data);

export const DeleteFromVoucher = async (name, data) =>
  await apiCall("DELETE", `v1/vouchers/${name}`, data);

export const DeleteAllUserVoucher = async (data) =>
  await apiCall("PUT", "v1/vouchers/users/removemultiple", data);

export const getReviews = async (e) =>
  await apiCall("GET", `v1/${e.queryKey[1]}`, {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    term: e.queryKey[2] || "",
    rate: e.queryKey[4] || "",
    phone: e.queryKey[5] || "",
    status: e.queryKey[6] || "",
  });

export const editReviews = async (pathName, data) =>
  await apiCall("PUT", `v1/${pathName}`, data);

export const replaceImage = async (pathName, data) => {
  return await apiCall("PUT", `v1/${pathName}`, data, true);
};

export const removeFee = async (id) =>
  await apiCall("DELETE", "v1/deliveryfee", { id });

export const removeServiceFee = async (id) =>
  await apiCall("DELETE", "v1/servicefee", { id });

export const createFee = async (data) =>
  await apiCall("POST", "v1/deliveryfee", data);

export const createServiceFee = async (data) =>
  await apiCall("POST", "v1/servicefee", data);

export const editFee = async (data) =>
  await apiCall("PUT", "v1/deliveryfee", data);

export const editServiceFee = async (data) =>
  await apiCall("PUT", "v1/servicefee", data);

export const getFee = async (e) =>
  await apiCall("GET", "v1/deliveryfee", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const getServiceFee = async (e) =>
  await apiCall("GET", "v1/servicefee", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const getDistance = async (e) =>
  await apiCall("GET", "v1/deliveryDistance", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const removeDistance = async (id) =>
  await apiCall("DELETE", "v1/deliveryDistance", { id });

export const editDistance = async (data) =>
  await apiCall("PUT", "v1/deliveryDistance", data);

export const createDistance = async (data) =>
  await apiCall("POST", "v1/deliveryDistance", data);

//=================================

export const removeData = async (pathName, id) =>
  await apiCall("DELETE", `v1/${pathName}`, { id });

//==================================

export const createOrderStatus = async (data) =>
  await apiCall("POST", "v1/orderstatus", data);

export const editOrderStatus = async (data) =>
  await apiCall("PUT", "v1/orderstatus", data);

export const getOrderStatus = async (e) =>
  await apiCall("GET", "v1/orderstatus", {
    page_size: e.queryKey[3] ? e.queryKey[3] : 100,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const createPaymentType = async (data) =>
  await apiCall("POST", "v1/paymenttypes", data);

export const editPaymentType = async (data) =>
  await apiCall("PUT", "v1/paymenttypes", data);

export const getPaymentType = async (e) =>
  await apiCall("GET", "v1/paymenttypes", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

// Review Reasons Functions

export const createReviewReasons = async (data) =>
  await apiCall("POST", "v2/reviewreasons", data);

export const editReviewReasons = async (data) =>
  await apiCall("PUT", "v1/reviewreasons", data);

export const getReviewReasons = async (e) =>
  await apiCall("GET", "v1/reviewreasons", {
    page_size: e.queryKey[4] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    category: e.queryKey[3] || "",
  });

// MSG Template Function
export const editMsgTemplate = async (data) =>
  await apiCall("PUT", "v1/msgtemplate", data);

export const removeMsgTemplate = async (id) =>
  await apiCall("DELETE", "v1/msgtemplate", { id });

export const createMsgTemplate = async (data) =>
  await apiCall("POST", "v1/msgtemplate", data);

export const getMsgTemplate = async (e) =>
  await apiCall("GET", "v1/msgtemplate", {
    page_size: pageSize,
    page_num: e.queryKey[1],
    term: e.queryKey[2] || "",
  });

export const editMsgTemplateStatus = async (data) =>
  await apiCall("PUT", "v1/msgtemplate/status", data);

// Auth Group Function
export const createAuthGroup = async (data) =>
  await apiCall("POST", "v1/roles", data);

export const getAuthGroup = async (e) =>
  await apiCall("GET", "v1/roles", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[1],
    term: e.queryKey[2] || "",
  });

export const getRolesList = async (e) =>
  await apiCall("GET", "v1/roles/list", {
    page_num: e.queryKey[1],
  });

export const getAuthGroupById = async (e) =>
  await apiCall("GET", "v1/roles/search", {
    id: e.queryKey[1],
  });

export const removeAuthGroup = async (id) =>
  await apiCall("DELETE", "v1/roles", { id });

export const updateAuthGroup = async (data) =>
  await apiCall("PUT", "v1/roles", data);

//=======================================
export const createDeclineReason = async (data) =>
  await apiCall("POST", "v1/declinereason", data);

export const editDeclineReason = async (data) =>
  await apiCall("PUT", "v1/declinereason", data);

export const getDeclineReason = async (e) =>
  await apiCall("GET", "v1/declinereason", {
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    userType: e.queryKey[4] || "",
  });

//=======================================
export const createVendorDiscount = async (data) =>
  await apiCall("POST", "v1/discount", data);

export const editVendorDiscount = async (data) =>
  await apiCall("PUT", "v1/discount", data);

export const editVendorDiscountStatus = async (data) =>
  await apiCall("PUT", "v1/discount/status", data);

export const getVendorDiscount = async (e) =>
  await apiCall("GET", "v1/discount/search", {
    page_size: pageSize,
    page_num: e.queryKey[2],
    term: e.queryKey[1] || "",
    active: e.queryKey[3] || "",
  });

export const getVendorDiscountById = async (id) =>
  await apiCall("GET", "v1/discount", { id });

//=================
export const getOrders = async (e) => {
  const boToken = await localStorage.getItem("bo_token");
  if (!boToken) return null;

  const queryParams = {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    id: e.queryKey[3] || "",
    vendorID: e.queryKey[4] || "",
    cityID: e.queryKey[5] || "",
    clientID: e.queryKey[6] || "",
    lastStatus: e.queryKey[7] || "",
    voucherCode: e.queryKey[8] || "",
    from: e.queryKey[9] ? dayjs(JSON.parse(e.queryKey[9])).toISOString() : "",
    to: e.queryKey[10] ? dayjs(JSON.parse(e.queryKey[10])).toISOString() : "",
    preOrder: e.queryKey[11] || false,
  };

  return await apiCall("GET", "v1/orders", queryParams);
};

export const getOrderInfo = async (e) =>
  await apiCall("GET", "v4/orders/info", { id: e.queryKey[1] });

export const changeOrderStatus = async (data) =>
  await apiCall("PUT", "v1/orders/status", data);

export const changeDeclineReason = async (data) =>
  await apiCall("PUT", "v1/orders/decline", data);

// GET Delayed orders
export const getDelayedOrders = async (e) => {
  const boToken = await localStorage.getItem("bo_token");
  if (!boToken) return null;

  const queryParams = {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    id: e.queryKey[3] || "",
    vendorID: e.queryKey[4] || "",
    cityID: e.queryKey[5] || "",
    clientID: e.queryKey[6] || "",
    lastStatus: e.queryKey[7] || "",
    voucherID: e.queryKey[8] || "",
    from: e.queryKey[9] ? dayjs(JSON.parse(e.queryKey[9])).toISOString() : "",
    to: e.queryKey[10] ? dayjs(JSON.parse(e.queryKey[10])).toISOString() : "",
    preOrder: e.queryKey[11] || false,
  };

  return await apiCall("GET", "v1/orders/late", queryParams);
};

//===========================

export const createTopping = async (data) =>
  await apiCall("POST", "v1/subproduct", data, true);

export const editTopping = async (data) =>
  await apiCall("PUT", "v1/subproduct", data, true);

export const getTopping = async (e) =>
  await apiCall("GET", "v1/subproduct", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    groupID: e.queryKey[1],
    term: e.queryKey[2] || "",
  });

export const getSubProductGroup = async (e) =>
  await apiCall("GET", "v1/toppingGroup", {
    page_size: pageSize,
    page_num: e.queryKey[3] || 1,
    productID: e.queryKey[1],
    term: e.queryKey[2] || "",
  });

export const createToppingGroup = async (data) =>
  await apiCall("POST", "v1/toppingGroup", data);

export const editToppingGroup = async (data) =>
  await apiCall("PUT", "v1/toppingGroup", data);

export const createOrder = async (data) =>
  await apiCall("POST", "v3/orders", data);

export const editOrder = async (data) =>
  await apiCall("PUT", "v3/orders", data);

export const updateData = async (pathName, data) =>
  await apiCall("PUT", `v1/${pathName}`, data);

export const editVendorRadius = async (data) =>
  await apiCall("PUT", "v1/vendor/radius", data);

export const editVendorCloseTime = async (data) =>
  await apiCall("PUT", "v2/vendor/close", data);

export const getFoodParty = async (e) =>
  await apiCall("GET", "v1/foodparty", {
    bannerID: e.queryKey[6],
    vendorID: e.queryKey[5],
    productGroupID: e.queryKey[4] || "",
    page_size: e.queryKey[3] || pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
  });

export const getProductCount = async (e) =>
  await apiCall("GET", "v1/appbanners/products", { id: e.queryKey[0] });

export const editFoodParty = async (data) =>
  await apiCall("PUT", "v1/foodparty", data);

export const editFoodPartyLimit = async (data) =>
  await apiCall("PUT", "v1/appbanners/limit", data);

export const createFoodParty = async (data) =>
  await apiCall("POST", "v1/foodparty", data);

export const getFreeDelivery = async (e) =>
  await apiCall("GET", "v1/deliveryDiscount", {
    page_size: pageSize,
    page_num: e.queryKey[2] || 1,
    term: e.queryKey[1] || "",
    from: e.queryKey[3] ? dayjs(JSON.parse(e.queryKey[3])).toISOString() : "",
    to: e.queryKey[4] ? dayjs(JSON.parse(e.queryKey[4])).toISOString() : "",
    status: e.queryKey[5] || "",
  });

export const editFreeDeliveryStatus = async (data) =>
  await apiCall("PUT", "v1/deliveryDiscount/status", data);

export const editFreeDelivery = async (data) =>
  await apiCall("PUT", "v1/deliveryDiscount", data);

export const removeFreeDelivery = async (id) =>
  await apiCall("DELETE", "v1/deliveryDiscount", { id });

export const createFreeDelivery = async (data) =>
  await apiCall("POST", "v1/deliveryDiscount", data);

export const getDeliveryDiscountByVendor = async (e) =>
  await apiCall("GET", "v1/deliveryDiscount/byvendorid", {
    id: e.queryKey[1] || "",
  });

export const getVendorById = async (id) =>
  await apiCall("GET", "v1/vendor/id", {
    id: id,
  });

export const bikerDispatchTime = async (data) =>
  await apiCall("PUT", "v1/vendor/dispatchTime", data);

export const getInRangeAddress = async (e) =>
  await apiCall("GET", "v4/clients/address", {
    vendorID: e.queryKey[1],
    clientID: e.queryKey[2],
  });

export const getVendorByLoaction = async (e) =>
  await apiCall("GET", "v1/vendor/info", {
    vendorID: e.queryKey[1],
    lat: e.queryKey[2],
    lon: e.queryKey[3],
  });

export const getVendorDiscounts = async (e) =>
  await apiCall("GET", "v1/vendor/discounts", {
    vendorID: e.queryKey[1],
  });

export const getWaterfallProcess = async (e) =>
  await apiCall("GET", "v1/waterfall/process", {
    page_size: pageSize,
    page_num: e.queryKey[1],
    term: e.queryKey[2],
  });

export const editWaterfallProcess = async (data) =>
  await apiCall("PUT", "v1/waterfall/process", data);

export const createWaterfallProcess = async (data) =>
  await apiCall("POST", "v1/waterfall/process", data);

export const removeWaterfallProcess = async (id) =>
  await apiCall("DELETE", "v1/waterfall/process", { id });

export const editWaterfallProcessStatus = async (data) =>
  await apiCall("PUT", "v1/waterfall/process/status", data);

export const getWaterfallProcessAction = async (e) =>
  await apiCall("GET", `v1/waterfall/process/${e.queryKey[1]}`, {
    page_size: pageSize,
    page_num: e.queryKey[2],
    term: e.queryKey[3],
  });

export const editWaterfallProcessAction = async (processID, data) =>
  await apiCall("PUT", `v1/waterfall/process/${processID}`, data);

export const createWaterfallProcessAction = async (processID, data) =>
  await apiCall("POST", `v1/waterfall/process/${processID}`, data);

export const removeWaterfallProcessAction = async (processID, id) =>
  await apiCall("DELETE", `v1/waterfall/process/${processID}`, id);

export const getvendorsWeights = async (e) =>
  await apiCall("GET", `v1/score/weights`, {});

export const editWeightsSettings = async (data) =>
  await apiCall("PUT", `v1/score/weights`, data);

export const getDistanceSettings = async (e) =>
  await apiCall("GET", "v1/score/distance", {
    page_num: e.queryKey[1],
    page_size: e.queryKey[2] || pageSize,
  });

export const removeDistanceSetting = async (id) =>
  await apiCall("DELETE", "v1/score/distance", { id });

export const editDistanceSetting = async (data) =>
  await apiCall("PUT", "v1/score/distance", data);

export const createDistanceSetting = async (data) =>
  await apiCall("POST", "v1/score/distance", data);

export const getSortingSetting = async (e) =>
  await apiCall("GET", "v1/sortingSettings", {
    page_size: pageSize,
    page_num: e.queryKey[1],
  });

export const createSortingSetting = async (data) =>
  await apiCall("POST", "v1/sortingSettings", data);

export const editSortingSetting = async (data) =>
  await apiCall("PUT", "v1/sortingSettings", data);

export const removeSortingSetting = async (id) =>
  await apiCall("DELETE", "v1/sortingSettings", { id });

export const editSortingSettingStatus = async (data) =>
  await apiCall("PUT", "v1/sortingSettings/status", data);

export const editSortingSettingDistanceStatus = async (data) =>
  await apiCall("PUT", "v1/sortingSettings/distanceSettings/status", data);

export const editSortingSettingPinnedStatus = async (data) =>
  await apiCall("PUT", "v1/sortingSettings/pinnedVendors/status", data);

export const getBannersForDistanceSetting = async (e) =>
  await apiCall("GET", `v1/score/distance/${e.queryKey[2]}/banners`, {
    page_size: pageSize,
    page_num: e.queryKey[1],
  });
export const getDistanceAppbanner = async (e) =>
  await apiCall("GET", "v1/score/distance/banners", {
    page_num: e.queryKey[1],
    page_size: e.queryKey[2] || pageSize,
  });

export const AddBannersToDistanceSettings = async (id, data) =>
  await apiCall("PUT", `v1/score/distance/${id}/banners`, data);

export const editWeightsSettingsStatus = async (data) =>
  await apiCall("PUT", "v1/score/weights/status", data);

export const getVendorPerformanceScoring = async (e) =>
  await apiCall("GET", `v1/score`, {
    page_size: e.queryKey[6] || pageSize,
    page_num: e.queryKey[1],
    vendor_id: e.queryKey[2] || "",
    city_id: e.queryKey[3] || "",
    from: e.queryKey[4] || "",
    to: e.queryKey[5] || "",
  });

export const getVendorPerformanceCycleSettings = async (e) =>
  await apiCall("GET", `v1/cycleSettings`, {
    page_size: pageSize,
    page_num: e.queryKey[1],
  });

export const getInternalProduct = async (e) =>
  await apiCall("GET", `/v1/internalPrpductGroup`, {
    page_size: e.queryKey[2] || pageSize,
    page_num: e.queryKey[1],
  });

export const removeInternalProduct = async (id) =>
  await apiCall("DELETE", "/v1/internalPrpductGroup", { id });

export const createInternalProduct = async (data) =>
  await apiCall("POST", "v1/internalPrpductGroup", data);

export const editInternalProduct = async (data) =>
  await apiCall("PUT", "v1/internalPrpductGroup", data);

export const getTrendingVendorPage = async (e) =>
  await apiCall("GET", `/v1/product_trending_carousels`, {
    page_size: pageSize,
    page_num: e.queryKey[2],
    term: e.queryKey[1] || "",
  });

export const removeTrendingVendorPage = async (id) =>
  await apiCall("DELETE", "/v1/product_trending_carousels", { id });

export const createTrendingVendorPage = async (data) =>
  await apiCall("POST", "/v1/product_trending_carousels", data);

export const editTrendingVendorPage = async (data) =>
  await apiCall("PUT", "/v1/product_trending_carousels", data);

export const editTrendingVendorPageStatus = async (data) =>
  await apiCall("PUT", "/v1/product_trending_carousels/status", data);

////
export const getMealSuggestions = async (e) =>
  await apiCall("GET", `/v1/meal_suggestions_settings`, {
    page_size: pageSize,
    page_num: e.queryKey[2],
    term: e.queryKey[1] || "",
  });

export const removeMealSuggestions = async (id) =>
  await apiCall("DELETE", "/v1/meal_suggestions_settings", { id });

export const createMealSuggestions = async (data) =>
  await apiCall("POST", "/v1/meal_suggestions_settings", data);

export const editMealSuggestions = async (data) =>
  await apiCall("PUT", "/v1/meal_suggestions_settings", data);

export const editMealSuggestionsStatus = async (data) =>
  await apiCall("PUT", "/v1/meal_suggestions_settings/status", data);

////

export const putVendorPerformanceCycleSettings = async (data) => {
  return await apiCall("PUT", `v1/cycleSettings`, data);
};

export const getCompensationSettings = async (e) =>
  await apiCall("GET", `v1/compensation`, {
    page_size: pageSize,
    page_num: e.queryKey[1],
    type: e.queryKey[2] || "",
  });

export const removeCompensationSetting = async (id) =>
  await apiCall("DELETE", "v1/compensation", { id });

export const createCompensationSetting = async (data) =>
  await apiCall("POST", "v1/compensation", data);

export const editCompensationSetting = async (data) =>
  await apiCall("PUT", "v1/compensation", data);

export const editCompensationSettingStatus = async (data) =>
  await apiCall("PUT", "v1/compensation/status", data);

export const exportScoring = async (e) =>
  await apiCall("GET", `v1/score/export`, {
    vendor_id: e.queryKey[1] || 0,
    city_id: e.queryKey[2] || 0,
    from: e.queryKey[3],
    to: e.queryKey[4],
  });

export const getProductsWeights = async (e) =>
  await apiCall("GET", `v1/productWeights`, {});

export const editProductsSettings = async (data) =>
  await apiCall("PUT", `v1/productWeights`, data);

export const getProductGroupSorting = async (id) =>
  await apiCall("GET", `v1/vendor/productgroupsorting`, { id });

export const putProductGroupSorting = async (data) =>
  await apiCall("PUT", `v1/vendor/productgroupsorting`, data);

export const updatePaymentStatus = async (data) =>
  await apiCall("PUT", "v1/paymenttypes/status", data);

export const updateProductGroupSizes = async (data) =>
  await apiCall("PUT", "v1/productgroup/batch/size", data);

export const uploadPrepDispatch = async (data) =>
  await apiCall("POST", "v1/prep_dispatch/upload", data, true);

export const getFiles = async (e) =>
  await apiCall("GET", "v1/prep_dispatch/upload", {
    page_size: e.queryKey[2] || pageSize,
    page_num: e.queryKey[1],
  });

export const productUpload = async (vendorData) => {
  const data = await apiCall("POST", "v1/product/upload", vendorData);
  return data;
};

export const downloadFile = (endpoint, params) => {
  let urlString = `${LIVE_URL}/${endpoint}`;

  const q = new URLSearchParams({
    ...params,
    tz,
  });
  console.log("q", q);
  const token = localStorage.getItem("bo_token");

  return urlString + `?${q}&Authorization=${token}`;
};
