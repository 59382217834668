import {
  ArrowLeftOutlined,
  SaveOutlined,
  MailOutlined,
  StopOutlined,
  ClockCircleOutlined,
  ProfileOutlined,
  MinusCircleOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";
import { useApp } from "../../store";
import { useOrderView } from "./store";
import authFunc from "../../helper/auth";
import { CreateApologyMsg } from "./createApologyMsg";
import { CreateCancelReason } from "./createCancelReason";
import { EditTime } from "./editTime";
import { PaymentInfo } from "./paymentInfo";

import {
  Button,
  Typography,
  Space,
  Row,
  Col,
  Divider,
  Spin,
  Alert,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { BikerIcon, SubProductModal } from "../../comonents/global";
import { PureMap } from "./map";
import { OrderInfo } from "./info";
import { InvoiceInfo } from "./invoice";
import { StatusHistory } from "./statusHistory";
import { MoreInfo } from "./more";
import Countdown from "react-countdown";
import { useQuery, useMutation } from "react-query";
import {
  getOrderInfo,
  editOrder,
  sendToBiker,
  zeroBikerTotal,
  cancelHybridPay,
} from "../../api";
import { useEffect, useState } from "react";
import { queryClient } from "../../queryClient";
import { roundToNearestValidAmount } from "../../helper/roundToNearest";

const { Text, Title } = Typography;

export const OrderViewPage = () => {
  const {
    setOrder,
    clientAddressID,
    clientComment,
    totalValue,
    basket,
    voucher,
    orderStatusHistory,
    setOrderStatusHistory,
    setIsCreateModal,
    setIsCreateCancelModal,
    setIsEditTimeModal,
    order,
  } = useOrderView();
  const { user } = useApp();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isOverTime, setIsOverTime] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [declineReasonMSG, setDeclineReasonMSG] = useState("");

  const { mutate, isLoading } = useMutation(editOrder, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("orderInfo");
        navigate(-1);
      } else {
        message.error(data.err);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: zeroBikerTotalMutate, isLoading: zeroBikerTotalLoading } =
    useMutation(zeroBikerTotal, {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("orderInfo");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const {
    isLoading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(["orderInfo", id], getOrderInfo, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  useEffect(() => {
    if (orderData && orderData?.success && orderData?.data) {
      setOrder(orderData?.data);
      let jsonStatusHis = JSON.parse(orderData?.data?.order?.status);
      setOrderStatusHistory(jsonStatusHis);
      let jsonStatus = JSON.parse(orderData?.data?.order?.status);
      setOrderStatus(jsonStatus?.pop()?.status);
      let getDeclineReason = JSON.parse(orderData?.data?.order?.declineReason);
      setDeclineReasonMSG(getDeclineReason?.arTitle);
    }
  }, [orderData]);

  let statusJson;

  if (orderData && orderData?.success && orderData?.data) {
    statusJson = JSON.parse(orderData?.data?.order?.status);
  }

  const handleSave = () => {
    console.log("basket: ", basket);
    let orderInfo = orderData?.data;
    let _order = {
      id: orderInfo?.order?.id,
      // orderDetail: basket.map((el) => {
      //   return {
      //     productID: el?.product?.id,
      //     qty: el.qty,
      //     subProductsIDS: el?.product?.subProduct
      //       ? JSON.stringify([el?.product?.subProduct?.id])
      //       : "[]",
      //   };
      // }),
      orderDetail: basket.map((el) => {
        return {
          productID: el.product.id,
          qty: el.qty,
          subProductGroup: el?.product?.subProductGroup?.map((group) => {
            return {
              id: group.id,
              multiSelect: group.multiSelect,
              subProductsIDS: group?.subProductsIDS?.map((item) => {
                return {
                  id: item.id,
                  price: item.price,
                  name: item.name,
                };
              }),
            };
          }),
        };
      }),
      clientID: orderInfo?.order?.clientID,
      clientAddressID,
      vendorID: orderInfo?.order?.vendorID,
      CityID: orderInfo?.order?.cityID,
      paymentTypeID: orderInfo?.order?.paymentTypeID,
      voucherID: voucher ? voucher.id : 0,
      voucherCode: voucher ? voucher.code : "",
      discountCapID: orderInfo?.order?.discountCapID || 0,
      declineReason: "{}",
      clientComment,
      clientCommentAddress: "",
      freeGift: false,
      platform: orderInfo?.order?.platform,
      preOrder: orderInfo?.order?.preOrder,
      preOrderDeliveryTime: orderInfo?.order?.preOrderDeliveryTime,
      expectedDeliveryTime: orderInfo?.order?.expectedDeliveryTime,
      bikerPickupDateTime: orderInfo?.order?.bikerPickupDateTime,
    };

    mutate(_order);
  };

  const arraysAreEqual = (arr1, arr2) => {
    // Check if the lengths are equal
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort arrays to ensure correct comparison
    arr1.sort((a, b) => a.product.id - b.product.id);
    arr2.sort((a, b) => a.product.id - b.product.id);

    // Iterate through each element and compare product objects
    for (let i = 0; i < arr1.length; i++) {
      const product1 = arr1[i].product;
      const product2 = arr2[i].product;

      // Check if the specified keys are equal
      if (
        product1.endprice !== product2.endprice ||
        product1.price !== product2.price ||
        product1.name !== product2.name
      ) {
        return false;
      }
    }

    // If all elements are equal, return true
    return true;
  };

  const isSaveVisibale = () => {
    if (!clientAddressID && !clientComment && !totalValue) {
      return false;
    } else {
      let order = orderData?.data?.order;
      let orderClientAddressID = order?.clientAddressID;
      let orderClientComment = order?.clientComment;
      let orderValue =
        order?.subTotal - order?.discountTotalValue + order?.deliveryFee;
      let orderVoucher = order?.voucherTotalValue;
      let orderTotalValue =
        orderValue -
        orderVoucher +
        order.extraDeliveryCharge -
        (order.deliveryDiscountTotalValue + order.voucherDeliveryTotalValue) +
        order.serviceFee;

      console.log(
        "orderClientAddressID !== clientAddressID: ",
        orderClientAddressID !== clientAddressID
      );
      console.log(
        "orderClientComment !== clientComment: ",
        orderClientComment !== clientComment
      );
      console.log(
        "totalValue !== orderTotalValue: ",
        totalValue !== orderTotalValue
      );
      console.log(
        "arraysAreEqual(basket, order?.orderDetail): ",
        !arraysAreEqual(basket, order?.orderDetail)
      );

      console.log("totalValue: ", totalValue);
      console.log("orderTotalValue: ", orderTotalValue);

      return orderClientAddressID !== clientAddressID ||
        orderClientComment !== clientComment ||
        totalValue !== roundToNearestValidAmount(orderTotalValue)
        ? true
        : false;
    }
  };

  // console.log("****is visible:", isSaveVisibale());

  const { mutate: sendToBikerMutate, isLoading: sendToBikerLoading } =
    useMutation(sendToBiker, {
      onSuccess: (data) => {
        if (data.success) {
          message.success("Send Succefully.");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });
  const { mutate: mutateHypridPayment, isLoading: HypridPaymentLoading } =
    useMutation(cancelHybridPay, {
      onSuccess: (data) => {
        if (data.success) {
          message.success("Send Succefully.");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  // const { isLoading: reasonsLoading, error, data: reasonData } = useQuery(
  //   ["declineReasons", "", 1, 500],
  //   getDeclineReason,
  //   {
  //     retry: 2,
  //   }
  // );

  // const menu = (
  //   <Menu onClick={handleCancel}>
  //     {!reasonsLoading &&
  //       reasonData.success &&
  //       reasonData.data.records.map(
  //         (val) =>
  //           val.userType === "BACKOFFICE_USER" && (
  //             <Menu.Item key={val.id} onClick={() => navigate(-1)}>
  //               {val.arTitle}
  //             </Menu.Item>
  //           )
  //       )}
  //   </Menu>
  // );

  const isStatus = (status) => {
    return orderStatusHistory.find((el) => el.status === status) ? true : false;
  };

  const handleBikerPanel = () => {
    // if (order?.vendor?.isNewBox) {
    if (process.env.NODE_ENV === "production") {
      window.open(
        ` https://back.convoy.baly.iq/order/${order?.order?.bikerOrderID}`
      );
    } else {
      window.open(
        ` https://back.convoy.baly.dev/order/${order?.order?.bikerOrderID}`
      );
    }
    // } else {
    //   window.open(
    //     `https://apanel.aws.snpb.app/panel/orders/${order?.order?.bikerOrderID}`
    //   );
    // }
  };
  const handleHybridPayCancel = () => {
    mutateHypridPayment({
      orderID: order?.order?.orderDetail[0]?.orderID,
      UserID: user?.id,
      InvoiceID: order?.order?.merchandiseInvoiceId,
      Status: "CANCELED",
    });
  };

  const handleZeroBikerTotal = () => {
    zeroBikerTotalMutate({
      id: Number(order?.order?.id),
      vendorID: order?.order?.vendorID,
    });
  };

  if (orderError)
    return <Alert message={orderError.message} type="error" showIcon />;

  if (!orderLoading && !orderData.success)
    return <Alert message={orderData.msg} type="error" showIcon />;

  return orderLoading ? (
    <Spin tip="Loading . . ." spinning={true} style={{ marginTop: "40vh" }} />
  ) : (
    <div className="app-page order-view-page" style={{ marginTop: 0 }}>
      <div className="app-space-between">
        <Space>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate("/")}
          />

          <div>
            <Space>
              <Button
                size="small"
                type="text"
                icon={
                  <ClockCircleOutlined
                    style={{
                      fontSize: 18,
                      color:
                        orderStatus === "DELIVERED" ||
                        orderStatus === "REJECTED" ||
                        orderStatus === "BACKOFFICE_REJECTED"
                          ? "#ccc"
                          : isOverTime
                          ? "#f81866"
                          : "#000",
                    }}
                  />
                }
                onClick={() => setIsEditTimeModal(true)}
              />
              <Title
                style={{
                  margin: 0,
                  lineHeight: 1,
                  marginTop: 4,
                  color:
                    orderStatus === "DELIVERED" ||
                    orderStatus === "REJECTED" ||
                    orderStatus === "BACKOFFICE_REJECTED"
                      ? "#ccc"
                      : isOverTime
                      ? "#f81866"
                      : "#000",
                }}
                level={4}
              >
                <Countdown
                  overtime={
                    orderStatus !== "DELIVERED" &&
                    orderStatus !== "REJECTED" &&
                    orderStatus !== "BACKOFFICE_REJECTED"
                      ? true
                      : false
                  }
                  onTick={({ total }) => total < 0 && setIsOverTime(true)}
                  daysInHours={true}
                  date={orderData?.data?.order?.expectedDeliveryTime}
                />
              </Title>
              <Text>|</Text>
              <Title
                style={{
                  margin: 0,
                  lineHeight: 1,
                  marginTop: 4,

                  opacity: 0.6,
                }}
                level={4}
              >
                #{orderData?.data?.order?.trackNo}
              </Title>
            </Space>

            <br />
            <Text type="secondary" style={{ fontSize: 14 }}>
              View and edit Order from this page.
            </Text>
          </div>
        </Space>
        <Space align="end">
          {/* <Button icon={<CloseOutlined />} size={"small"} /> */}

          {/* <Tag  className="timer-card" style={{ padding: 6 }}>
            <Space>
              <ClockCircleOutlined />
              <Countdown
                overtime={true}
                daysInHours={true}
                date={Date.now() + 10000}
              />
            </Space>
          </Tag> */}
          <Divider type="vertical" />
          <Button
            onClick={handleHybridPayCancel}
            icon={<CloseSquareOutlined />}
            disabled={
              order?.order?.hybridPaymentStatus === "PENDING" &&
              isStatus("ARRIVED_AT_PICKUP")
                ? false
                : true
            }
          >
            Cancel Hybrid Payment
          </Button>
          {/* <Button
            className="biker-btn"
            icon={<BikerIcon style={{ width: 20 }} />}
            disabled={!isStatus("MANUAL") || order?.vendor?.isNewBox}
            loading={sendToBikerLoading}
            onClick={() =>
              sendToBikerMutate({
                id: orderData?.data?.order?.id,
              })
            }
          >
            Send to biker
          </Button> */}
          <Button
            onClick={handleBikerPanel}
            icon={<ProfileOutlined />}
            disabled={!order?.order?.bikerOrderID}
          >
            Biker Panel Order
          </Button>
          <Popconfirm
            placement="topRight"
            title="Confirm Zero Biker Total?"
            onConfirm={handleZeroBikerTotal}
          >
            <Button
              loading={zeroBikerTotalLoading}
              icon={<MinusCircleOutlined />}
              disabled={
                statusJson.slice(-1)[0].status === "BACKOFFICE_REJECTED" &&
                order?.order?.bikerOrderID
                  ? false
                  : true
              }
            >
              zero biker total
            </Button>
          </Popconfirm>
          <Button
            onClick={() => setIsCreateModal(true)}
            icon={<MailOutlined />}
          >
            Send Apology message
          </Button>
          {basket.length > 0 &&
            orderStatus !== "ACCEPTED" &&
            orderStatus !== "DELIVERED" &&
            isSaveVisibale() && (
              <Popconfirm
                placement="topRight"
                title="Confirm Save!"
                onConfirm={handleSave}
              >
                <Button
                  // disabled={isStatus("PICKED_UP") || isStatus("REJECTED") || isStatus("CANCELLED")}
                  loading={isLoading}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Save & Close
                </Button>
              </Popconfirm>
            )}

          {/* <Popconfirm placement="topRight" title="Confirm Cancel!"> */}
          {/* <Dropdown
              disabled={isStatus("DELIVERED") || isStatus("BACKOFFICE_REJECTED")}
              overlay={menu}
              placement="bottomRight"
              arrow> */}
          {authFunc("ORDER_DELETE") && (
            <Tooltip
              placement="bottom"
              title={!isStatus("DELIVERED") ? declineReasonMSG : "DELIVERED"}
            >
              <Button
                disabled={
                  isStatus("DELIVERED") ||
                  isStatus("BACKOFFICE_REJECTED") ||
                  isStatus("REJECTED")
                }
                onClick={() => setIsCreateCancelModal(true)}
                icon={<StopOutlined />}
                type="primary"
                danger
              >
                Cancel Order
              </Button>
            </Tooltip>
          )}
          {/* </Dropdown> */}
          {/* </Popconfirm> */}

          {/* <Card>expecteddeliverytime</Card> */}
        </Space>
      </div>
      <Divider />

      <Row gutter={[15, 15]}>
        <Col span={7}>
          <OrderInfo />
          <PureMap mapHeight={"18vw"} />
        </Col>

        <Col span={11}>
          <InvoiceInfo />
        </Col>

        <Col span={6}>
          <MoreInfo />
          <PaymentInfo />
          <StatusHistory />
        </Col>
      </Row>
      {authFunc("SEND_APOLOGY_VOUCHER") && <CreateApologyMsg />}
      <CreateCancelReason paymentType={order?.order?.paymentType} />
      <SubProductModal />
      <EditTime />
    </div>
  );
};
