import { Card, Button, Breadcrumb, Space, Pagination } from "antd";
import { PuerTable } from "./table";
import { PureCreate } from "./create";
import { useCSV } from "./store";

import { PlusOutlined } from "@ant-design/icons";
import authFunc from "../../helper/auth";


export const CSVPage = () => {
  const { setIsCreateModal } = useCSV();

  return (
    <div className="app-page">
     
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Restaurants</Breadcrumb.Item>
        <Breadcrumb.Item>Prep Time + Dispatch Time CSV</Breadcrumb.Item>
      </Breadcrumb>

      
      <Card>
      
        <Space
          style={{
            display: "flex",
            justifyContent:'flex-end',
            marginBottom: 16,
          }}
        >

          
        <div>
          {authFunc("PREP_DISPATCH_CREATE") && (
            <Button
             type="primary"
             onClick={() => setIsCreateModal(true)}
             icon={<PlusOutlined />}
              >
              Create New 
            </Button>
          )}
        </div>
         
         
        </Space>

       
        <PuerTable />

     
        <PureCreate />
      
    
      </Card>
    </div>
  );
};
