import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Spin,
  message,
  Select,
  Card,
  Space,
  Tag,
  Input,
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getRestaurantsNames, vendorToBanner, getAppbannersVendor } from "../../api";
import { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Avatar from "antd/lib/avatar/avatar";
import { arrayMoveImmutable } from "array-move";
const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

export const AddVendors = () => {
  const {
    id,
    setIsAddVendor,
    isAddVendor,
    appBanner_vendor,
    setAppBannerVendor,
    type,
    setType,
    setPolygon,
    setPriorty,
    setId,
    setName,
    setImg,
    setEndDate,
    setStartDate,
  } = useAppBanner();
  const [list, setList] = useState([]);
  const [searchlist, setSearchList] = useState([]);
  const [filter, setFilter] = useState(null);
  const [vendorSearch, setVendorSearch] = useState("");

  const handleVendorSearch = (val) => {
    setVendorSearch(val);

    if (val !== "") {
      let vendorSearch = list.filter((el) => el.arName && el.arName.toLowerCase().includes(val.toLowerCase()))
      setSearchList(
        vendorSearch.length !== 0 ? vendorSearch : [{ searchRes: false }]
      );
    } else {
      setSearchList([]);
    }
  };

  const SortableItem = SortableElement(({ value }) => (
    <Card
      bordered={false}
      bodyStyle={{ padding: "10px 16px", backgroundColor: "#f6f6f6" }}
      style={{ marginBottom: 10 }}
    >
      <div className="app-space-between" style={{ alignItems: "center" }}>
        <Space size={16}>
          <Avatar size="large" src={value.logoImg} />
          <Space direction="vertical" size={0}>
            <Text>{value.name || value.arName}</Text>
            <Text type="secondary" style={{ fontSize: 12 }}>
              {value.address}
            </Text>
          </Space>
        </Space>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setList(list.filter((el) => el.id != value.id));
            if (vendorSearch) {
              setSearchList(
                searchlist.filter((el) => el.id != value.id).length !== 0
                  ? searchlist.filter((el) => el.id != value.id)
                  : [{ searchRes: false }]
              );
            }
          }}
          icon={<DeleteOutlined />}
          size="small"
          type="text"
          danger
        />
      </div>
    </Card>
  ));
  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) =>
          value.searchRes !== false ? (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ) : null
        )}
      </div>
    );
  });

  const { isLoading: resturantLoading, data: restaurants } = useQuery(
    ["resturants", filter],
    getRestaurantsNames,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isAddVendor,
    }
  );

  const { isLoading: bannerLoading, data: bannerData } = useQuery(
    ["appbanners/vendors", filter, id],
    getAppbannersVendor,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isAddVendor,
    }
  );

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    vendorToBanner,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsAddVendor(false);
          queryClient.invalidateQueries("appBanner");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  let onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMoveImmutable(list, oldIndex, newIndex));
  };

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  const handleSave = () => {
    let dataForm = {
      appBanner_vendor: list.map((el, index) => {
        return { vendorID: el.id, priority: index + 1 };
      }),
      id,
      type,
    };

    editMutate(dataForm);
  };
  const resetState = () => {
    setId(null);
    setImg(null);
    setType(null);
    setStartDate(null);
    setEndDate(null);
    setName(null);
    setPolygon(null);
    setPriorty(null);
    setFilter(null);
    setVendorSearch("");
    setList([]);
    setSearchList([]);
    setAppBannerVendor([]);
  };
  useEffect(() => {
    if (id && filter === null) {
      setList(
        bannerData && bannerData.data
          ? bannerData?.data?.sort((a, b) => (b.priority > a.priority ? -1 : 1))
          : []
      );
    }
  }, [id, bannerData]);
  return (
    <Modal
      title={<Title level={5}>Vendor to Banner</Title>}
      className="app-modal"
      centered
      visible={isAddVendor}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsAddVendor(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Search
            onSearch={(val) => handleVendorSearch(val)}
            placeholder="Search on resturant in this banner..."
          />
        </Col>
        <Col span={24}>
          <Select
            showSearch
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            onChange={(val) => {
              if (val) {
                if (type == "BANNER") {
                  setList([
                    restaurants?.data?.records?.find((el) => el.id == val),
                  ]);
                } else {
                  setList([
                    ...list,
                    restaurants?.data?.records?.find((el) => el.id == val),
                  ]);
                }
              }
            }}
            style={{ width: "100%" }}
            placeholder="Find Resturant to add on this banner"
            allowClear
          >
            {!resturantLoading ? (
              restaurants?.success ? (
                restaurants?.data?.records?.map((el) => (
                  <Option
                    key={el.id}
                    id={el.id}
                    value={el.id}
                    disabled={isRepeated(el.id)}
                  >
                    {el.name}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>
        <Col span={24}>
          <div style={{ minHeight: 400, maxHeight: "70vh", overflow: "auto" }}>
            <SortableList
              distance={1}
              items={searchlist.length > 0 ? searchlist : list}
              onSortEnd={onSortEnd}
            />
          </div>
        </Col>
        <Col span={18}>
          <Button
            loading={editLoading || resturantLoading}
            disabled={list.length === 0}
            type="primary"
            onClick={handleSave}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Col>
        <Col span={6} style={{ marginTop: "6px" }}>
          <Tag color="pink"> Total Vendor {list.length}</Tag>
        </Col>
      </Row>
    </Modal>
  );
};
