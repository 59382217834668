import {
    Form,
    Button,
    Typography,
    Modal,
    Row,
    Col,
    Upload,
    Select,
    message,
  } from "antd";
  import { FileAddOutlined, LoadingOutlined } from "@ant-design/icons";
  import { useState } from "react";
  import { useMutation } from "react-query";
  import { uploadPrepDispatch } from "../../api" 
import { useCSV } from "./store";
import { queryClient } from "../../queryClient";
  
  const { Title } = Typography;
  const { Dragger } = Upload;
  const { Option } = Select;
  
  export const PureCreate = () => {
    const [file, setFile] = useState(null); 
    const [selectedType, setSelectedType] = useState(null); 
    const [uploading, setUploading] = useState(false);
   const {
       isCreateModal,
       setIsCreateModal,
 
     } = useCSV();
   
  
     const { mutate: uploadFile, isLoading } = useMutation(uploadPrepDispatch, {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("upload");
          resetState();
          setIsCreateModal(false);
        } else {
          message.error(data.err);
        }
      },
      onError: (error) => {
        message.error(error.message || "Failed to upload.");
      },
    });
  
  
      let fileProps = {
        name: "file",
        multiple: false,
        maxCount: 1,
        disabled: isLoading ? true : false,
        beforeUpload: () => {
          return false;
        },
      };
    
      const onFinish = (values) => {
      
          values.file = values.file.file;
          values.type=selectedType
          uploadFile(values);
        
      };
  
    const resetState = () => {
      setFile(null);
      setSelectedType(null);
    };
  
    return (
      <>
    
        <Modal
          title={<Title level={5}>Upload Prep or Dispatch</Title>}
          visible={isCreateModal}
          centered
          onCancel={() => {
            resetState();
            setIsCreateModal(false);
          }}
          footer={null}
          destroyOnClose
        >
          <Form requiredMark={"optional"}
        name="basic"
       
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical">
          
            <Form.Item label="Select Type" required>
              <Select
                placeholder="Choose Type"
                value={selectedType}
                onChange={(value) => setSelectedType(value)}
                style={{ width: "100%" }}
                required
              >
                <Option value="prep">Prep</Option>
                <Option value="dispatch">Dispatch</Option>
              </Select>
            </Form.Item>
  
          
            <Form.Item label="Upload File" name="file" required>
  <Dragger {...fileProps}>
    {!uploading ? (
      <>
        <p className="ant-upload-drag-icon">
          <FileAddOutlined />
        </p>
        <p className="ant-upload-text">Drag file here or click to upload</p>
        <p className="ant-upload-hint">Only CSV files are allowed.</p>
      </>
    ) : (
      <>
        <p className="ant-upload-text">Uploading file...</p>
        <LoadingOutlined />
        <p className="ant-upload-hint">Please wait...</p>
      </>
    )}
  </Dragger>
</Form.Item>
  
            
            <Form.Item>
  <Button
    type="primary"
    htmlType="submit"
    loading={uploading}
    block
  >
    Upload File
  </Button>
</Form.Item>
          </Form>
        </Modal>
      </>
    );
  };
  